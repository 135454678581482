<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Cadastro de Treinamentos</h1>
      <div class="container-btns" v-if="perfil != 5 && perfil != null">
        <button class="btn btn-laranja" @click="adicionarBanner">Novo Treinamento</button>
      </div>
      <form @submit.prevent="pesquisaForm" class="formulario-container">
        <div class="formulario-linha linha-tripla">
          <div class="formulario-item">
            <label class="formulario-item-label">Tipo de treinamento</label>
            <multiselect v-model="form.tipoTreinamento" label="descricao" track-by="descricao" :options="tipoTreinamentos"
              :allow-empty="true" :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Nome do Treinamento</label>
            <multiselect v-model="form.nome" label="descricao" track-by="descricao" :options="nomes" :searchable="true"
              :allow-empty="true" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado">
              <span slot="noResult">Treinamento não encontrado</span>
            </multiselect>
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Data inicio</label>
            <datepicker :language="ptBR" v-model="form.dataInicio" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Data final</label>
            <datepicker :language="ptBR" v-model="form.dataFim" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Status</label>
            <multiselect v-model="form.treinamentoAtivo" label="descricao" track-by="descricao" :options="ativoInativo"
              :searchable="true" :allow-empty="true" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado">
            </multiselect>
          </div>
        </div>

        <div class="container-btns">
          <button type="button" class="btn btn-cancelar" @click="limparFiltro">Limpar</button>
          <button type="submit" class="btn btn-laranja">Filtrar</button>
        </div>
      </form>

      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados</p>
      </div>

      <Tabela v-else :colunas="colunas" :linhas="bannerTreinamentos" :paginacao="true" :pagina="paginaBusca"
        :totalPaginas="totalPaginas" :funcProximaPag="proxPagina" :funcAnteriorPag="pagAnterior" />

    </div>
  </div>
</template>

<script>
import Tabela from "@/components/Tabela/Tabela.vue";
import { listarTipoTreinamento, listarTreinamento, listarNomeTreinamento } from "@/services/banner-treinamento";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { masks, objects } from '@/assets/js'

export default {
  name: "CadastroBannerTreinamentosListar",

  components: {
    Tabela,
    Multiselect,
    Datepicker
  },

  data() {
    return {
      ptBR: ptBR,
      semDados: false,
      paginaBusca: 1,
      qtdPorPagina: 10,
      totalPaginas: 0,
      objReferencia: {},
      ativoInativo: [
        { id: 1, descricao: "Ativo" },
        { id: 0, descricao: "Inativo" }
      ],
      colunas: [
        { key: "tipoTreinamento", titulo: 'Tipo de Treinamento', tamanho: 'grande', link: false },
        { key: "nomeTreinamento", titulo: 'Nome do Treinamento', tamanho: 'grande-visivel', link: false },
        // { key: "link", titulo: 'Link', tamanho: 'grande', link: false },
        { key: "dataInicio", titulo: 'Data Início', tamanho: 'medio-130', link: false },
        { key: "dataFim", titulo: 'Data Fim', tamanho: 'medio-130', link: false },
        // { key: "imagemDesktop", titulo: 'Banner Desktop', tamanho: 'medio-110', link: false },
        // { key: "imagemMobile", titulo: 'Banner Mobile', tamanho: 'medio-110', link: false },
        { key: "", titulo: '', tamanho: 'btns', link: true, func: this.editarBanner },
      ],
      bannerTreinamentos: [],
      tipoTreinamentos: [],
      nomes: [],
      form: {
        nome: [],
        tipoTreinamento: [],
        dataInicio: "",
        dataFim: "",
        treinamentoAtivo: { id: 1, descricao: "Ativo" },
      },
      perfil: null
    };
  },

  mounted() {
    Promise.all(this.requisicoes())
    this.listarTreinamentos();
    this.perfil = localStorage.getItem('perfil');
  },

  methods: {
    requisicoes() {
      return [
        listarTipoTreinamento().then(resp => {
          this.tipoTreinamentos = resp.data
        }),
        listarNomeTreinamento().then(resp => {
          this.nomes = resp.data
        })
      ]
    },
    limparFiltro() {
      this.form = {
        nome: [],
        tipoTreinamento: [],
        dataInicio: "",
        dataFim: "",
        treinamentoAtivo: ""
      }
    },
    adicionarBanner() {
      this.$router.push("/cadastro-banner-treinamentos/adicionar");
    },
    editarBanner(treinamentoId) {
      return `/cadastro-banner-treinamentos/editar/${treinamentoId}`;
    },
    filtro() {
      const data = {
        nomeUsuario: "", // Default
        cpf: "", // Default
        campanha: 0, // Default
        dataConclusao: "", // Default
        dataInicio: this.form.dataInicio ? masks.formatarData(this.form.dataInicio, 'YYYY-MM-DD') : "",
        dataFim: this.form.dataFim ? masks.formatarData(this.form.dataFim, 'YYYY-MM-DD') : "",
        mesVigente: 0, // Default
        anoVigente: 0, // Default
        treinamentoAtivo: this.form.treinamentoAtivo != "" ? this.form.treinamentoAtivo.id : 1,
        idTipo: this.form.tipoTreinamento ? this.form.tipoTreinamento.id : 0,
        idNome: this.form.nome ? this.form.nome.id : 0,
        idCargo: 0, // Default
        pagina: this.paginaBusca,
        qtdPorPagina: this.qtdPorPagina,
      }

      return data
    },
    pesquisaForm() { // Funçao para evitar requisiçao desnecessária
      if (objects.objetosIguais(this.objReferencia, this.filtro())) { // caso todos os campos sao iguais
        return this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Nenhum campo foi alterado. Terá que mudar algum filtro para fazer uma nova busca',
          tipo: "erro",
        })
      }
      this.paginaBusca = 1
      this.listarTreinamentos()
    },
    listarTreinamentos() {
      this.objReferencia = this.filtro()
      listarTreinamento(this.filtro()).then((resp) => {
        const { lista, paginacao } = resp.data

        if (lista.length) {
          this.semDados = false
          this.totalPaginas = paginacao.totalPaginas
          this.bannerTreinamentos = lista
          return;
        }
        this.semDados = true
      });
    },
    pagAnterior() {
      this.paginaBusca--;
      this.listarTreinamentos();
    },
    proxPagina() {
      this.paginaBusca++;
      this.listarTreinamentos();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />